import { Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { BattlePlayLevelEnum } from '@settings/appSettings/types/enums/BattlePlayLevelEnum';
import { UserGameMatchOutcomeEnum } from '@users/types/enums/UserGameMatchOutcomeEnum';
import { IUserGameMatch, userGameMatchesKeys } from '@users/types/IUserGameMatch';

export const userGameMatchesColumns = ({
  onOpenHistoryModal,
}: {
  onOpenHistoryModal?: (record: IUserGameMatch) => void;
}): ColumnsType<IUserGameMatch> => [
  {
    dataIndex: userGameMatchesKeys.id,
    render: (value: IUserGameMatch['id'], record) => (
      <Typography.Text
        className="text-ant-blue-6 cursor-pointer"
        onClick={() => {
          onOpenHistoryModal?.(record);
        }}
      >
        {value}
      </Typography.Text>
    ),
    title: 'ID',
  },
  {
    dataIndex: userGameMatchesKeys.outcome,
    render: (outcome: IUserGameMatch['outcome']) => UserGameMatchOutcomeEnum[outcome],
    title: 'Результат',
  },

  {
    dataIndex: userGameMatchesKeys.computerRatingElo,
    title: 'Рейтинг компьютера',
  },
  {
    dataIndex: userGameMatchesKeys.oldRating,
    title: 'До игры',
  },
  {
    dataIndex: userGameMatchesKeys.newRating,
    title: 'После игры',
  },
  {
    dataIndex: userGameMatchesKeys.mode,
    render: (outcome: IUserGameMatch['mode']) => BattlePlayLevelEnum[outcome],
    title: 'Режим игры',
  },
];
